import { FC, useRef } from "react"
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { MenuItem } from "../../types";
import { Header } from "../../components/patterns";
import { Contact, ContentBox, Headline, Location, MainBanner, Reviews } from "../../components/design-system";
import { pensionLawServices } from "../../static/pensionLawServices";
import { scrollToElement } from "../../utils/scrollToElement";
import pensionLawBanner from '../../assets/img/pensionLawBanner.png'

const PensionLawLandingPage: FC = () => {

    const { palette, typography } = useTheme()
    const matches = useMediaQuery('(min-width:600px)');

    const refHome = useRef<HTMLDivElement>(null);
    const refLoas = useRef<HTMLDivElement>(null);
    const refServices = useRef<HTMLDivElement>(null);
    const refReview = useRef<HTMLDivElement>(null);
    const refLocation = useRef<HTMLDivElement>(null);
    const refContact = useRef<HTMLDivElement>(null);

    const menu: MenuItem[] = [
        { id: 'InicioClick', title: 'Início', link: '#!', hash: '#home', ref: refHome },
        { id: 'LoasClick', title: 'LOAS', link: '#!', hash: '#loas', ref: refLoas },
        { id: 'ServicesClick', title: 'Serviços', hash: '#services', link: '#!', ref: refServices },
        { id: 'AvaliacoesClick', title: 'Avaliações', hash: '#avaliations', link: '#!', ref: refReview },
        { id: 'LocalizacaoClick', title: 'Localização', hash: '#localization', link: '#!', ref: refLocation }
    ];

    return (
        <Box>
            <Header menu={menu} homeRef={refHome} contactRef={refContact} mainColor={palette.lightVine} />

            <MainBanner
                mainImage={pensionLawBanner}
                preTitle='Direito Previdenciário'
                title='Precisa de um <br/> especialista?'
                description='Atendimento online em todo o Brasil'
                theme={{
                    backgroundColor: palette.vine,
                    buttonColor: palette.lightVine,
                    textColor: palette.light,
                    buttonTextColor: "#fff",
                    image: {
                        size: { mobile: '236px', desktop: '430px' },
                        marginLeft: { mobile: '-22px', desktop: '80px' }
                    }
                }}
                onClick={() => { (window as any).dataLayer.push({'event': 'FaleComigoPrevidenciarioClick'}); scrollToElement(refContact)}} />

            <Container maxWidth="lg">

                <Box ref={refLoas} pt={matches ? '5rem' : '30px'}>

                    <Headline
                        title="Direito ao Benefício Assistencial ao Idoso e ao Deficiente (LOAS)"
                        titleColor={palette.vine}
                        subtitle="Saiba que você, idoso ou pessoa com deficiência, tem seus direitos garantidos."
                    />

                    <Box pt={matches ? '3rem' : '30px'}>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize='15px'
                            lineHeight={2}
                            fontWeight={typography.fontWeightLight}
                            mb='20px'
                            textAlign={'center'}
                            >
                                Oferecemos assistência especializada para assegurar o benefício assistencial do LOAS, garantindo um salário mínimo mensal para idosos com 65 anos ou mais e para pessoas com deficiência que comprovem não possuir meios de subsistência. O benefício também inclui a proteção de direitos fundamentais, como acesso a cuidados médicos e suporte social necessário para uma vida digna.
                        </Typography>
                    </Box>
                   
                </Box>

                <Box ref={refServices} pt={matches ? '5rem' : '30px'}>
                    <Headline
                        title="Serviços"
                        titleColor={palette.vine}
                        subtitle="Conheça em quais questões podemos te ajudar"
                    />

                    <Box mt='20px'>

                        <Grid container spacing={2}>
                            {pensionLawServices.map(({ id, icon, title, text }, i, arr) => (
                                <>
                                    {matches && i + 1 === arr.length && (
                                        <Grid item md={4} sm={3} xs={12} />
                                    )}
                                    <Grid item md={4} sm={6} xs={12}>
                                        <ContentBox titleColor={palette.vine} key={id} title={title} text={text} cardMinHeight='208px' />
                                    </Grid>
                                </>
                            ))}
                        </Grid>


                    </Box>       
                </Box>

                <Reviews titleColor={palette.vine} refReview={refReview} />
                <Location titleColor={palette.vine} refLocation={refLocation} />
            </Container>
            
            <Contact titleColor={palette.vine} refContact={refContact} />
        </Box>
    )
}

export { PensionLawLandingPage }