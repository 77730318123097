import React from 'react'

import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Header } from '../../components/patterns'
import { Contact } from '../../components/design-system/contact'
import { ContentBox, Headline, Location, MainBanner, Reviews } from '../../components/design-system'
import { laborLawServices } from '../../static'
import laborLawBanner from '../../assets/img/laborLawBanner.png'
import { scrollToElement } from '../../utils/scrollToElement'
import { MenuItem } from '../../types'

const LaborLawLandingPage: React.FC = () => {

    const { palette, typography } = useTheme()
    const matches = useMediaQuery('(min-width:600px)');
    
    const refHome = React.useRef<HTMLDivElement>(null);
    const refGestantes = React.useRef<HTMLDivElement>(null);
    const refServices = React.useRef<HTMLDivElement>(null);
    const refReview = React.useRef<HTMLDivElement>(null);
    const refLocation = React.useRef<HTMLDivElement>(null);
    const refContact = React.useRef<HTMLDivElement>(null);

    const menu: MenuItem[] = [
        { id: 'InicioClick', title: 'Início', link: '#!', hash: '#home', ref: refHome },
        { id: 'GestanteClick', title: 'Gestantes', link: '#!', hash: '#gestantes', ref: refGestantes },
        { id: 'ServicesClick', title: 'Serviços', hash: '#services', link: '#!', ref: refServices },
        { id: 'AvaliacoesClick', title: 'Avaliações', hash: '#avaliations', link: '#!', ref: refReview },
        { id: 'LocalizacaoClick', title: 'Localização', hash: '#localization', link: '#!', ref: refLocation }
    ];

    return (
        <Box>
            <Header menu={menu} homeRef={refHome} contactRef={refContact} mainColor={palette.lightBrown} />

            <MainBanner
                mainImage={laborLawBanner}
                preTitle='Direito Trabalhista'
                title='Precisa de um <br/> especialista?'
                description='Atendimento online em todo o Brasil'
                theme={{
                    backgroundColor: palette.brown,
                    buttonColor: palette.lightBrown,
                    textColor: palette.light,
                    buttonTextColor: "#fff",
                    image: {
                        size: { mobile: '282px', desktop: '511px' },
                        marginLeft: { mobile: '-26px', desktop: '50px' }
                    }
                }}
                onClick={() => { (window as any).dataLayer.push({'event': 'FaleComigoTrabalhistaClick'}); scrollToElement(refContact)}} />

            <Container maxWidth="lg">

                <Box ref={refGestantes} pt={matches ? '5rem' : '30px'}>

                    <Headline
                        title="Direito para gestantes"
                        titleColor={palette.brown}
                        subtitle="Saiba que você futura mamãe tem seus direitos"
                    />

                    <Box pt={matches ? '3rem' : '30px'}>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize='15px'
                            lineHeight={2}
                            fontWeight={typography.fontWeightLight}
                            mb='20px'
                            textAlign={'center'}
                            >
                                Garantia de direitos especiais para você futura mamãe, incluindo estabilidade no emprego desde a confirmação da gravidez até cinco meses após o parto, licença-maternidade remunerada de 120 dias, dispensa para consultas e exames médicos, e a transferência de função quando necessário para preservar a saúde da gestante e do bebê.
                        </Typography>
                    </Box>
                   
                </Box>

                <Box ref={refServices} pt={matches ? '5rem' : '30px'}>
                    <Headline
                        title="Serviços"
                        titleColor={palette.brown}
                        subtitle="Conheça em quais questões podemos te ajudar"
                    />

                    <Box mt='20px'>

                        <Grid container spacing={2}>
                            {laborLawServices.map(({ id, icon, title, text }, i, arr) => (
                                <>
                                    {matches && i + 1 === arr.length && (
                                        <Grid item md={4} sm={3} xs={12} />
                                    )}
                                    <Grid item md={4} sm={6} xs={12}>
                                        <ContentBox titleColor={palette.brown} key={id} title={title} text={text} cardMinHeight='208px' />
                                    </Grid>
                                </>
                            ))}
                        </Grid>


                    </Box>       
                </Box>

                <Reviews titleColor={palette.brown} refReview={refReview} />
                <Location titleColor={palette.brown} refLocation={refLocation} />
            </Container>
            
            <Contact titleColor={palette.brown} refContact={refContact} />
        </Box>
    )
}

export { LaborLawLandingPage }
