import { Box, Typography, Button, useTheme } from "@mui/material"
import { sx } from './sx';
import AddIcon from '@mui/icons-material/Add';

import { ReactSVG } from 'react-svg'

interface ContentBoxProps {
    title: string;
    text: string;
    icon?: string;
    onClick?: () => void;
    titleColor: string
    cardMinHeight?: string
}

const ContentBox: React.FC<ContentBoxProps> = ({ title, text, icon, onClick, titleColor, cardMinHeight }: ContentBoxProps) => {
    const { typography } = useTheme();
    const { css } = sx();

    return (
        <Box sx={css.box.base}>
            {icon && <Box sx={css.box.icon}>
                {/* <ReactSVG src={icon} /> */}
                <img src={icon} alt="icon image"/>
            </Box>}
            <Box sx={{...css.box.info.base, minHeight: cardMinHeight ?? 'auto'}}>
                <Typography
                    variant="body1"
                    component='h1'
                    fontSize='18px'
                    fontWeight={typography.fontWeightMedium}
                    mb='10px'
                    sx={{ color: titleColor }}>
                        {title}
                </Typography>
                <Typography
                    variant="body1"
                    component='h1'
                    fontSize='13px'
                    lineHeight={1.5}
                    fontWeight={typography.fontWeightLight}
                    mb='20px'
                    sx={css.box.title} dangerouslySetInnerHTML={{__html: text}}>
                </Typography>
                {onClick && <Box sx={css.box.info.button.wrapper}>
                    <Button
                        className="MuiButton outlined primary"
                        variant="outlined"
                        size="medium"
                        sx={css.box.info.button.base}
                        onClick={onClick}>
                        Saiba mais
                        <AddIcon sx={css.box.info.button.icon} />
                    </Button>
                </Box>}
            </Box>
        </Box>
    )
}

export { ContentBox };
