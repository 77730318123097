import Box from "@mui/material/Box"
import { Image } from "../../design-system";
import logo from '../../../assets/img/logo.png';
import { Button, Container, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { sx } from './sx';
import React, { RefObject } from "react";
import { MobileMenu } from "../mobile/menu";
import { useLocation } from 'react-router-dom';
import { MenuItem } from "../../../types";
import { scrollToElement } from "../../../utils/scrollToElement";

interface HeaderProps {
    menu: MenuItem[]
    homeRef: RefObject<HTMLDivElement>
    contactRef: RefObject<HTMLDivElement>
    mainColor: string
}

const Header: React.FC<HeaderProps> = ({ menu, homeRef, contactRef, mainColor }: HeaderProps) => {
    const { css } = sx();
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    const handleMenu = ({ type, callback }: { type: 'open' | 'close', callback?: () => void }) => {
        switch (type) {
            case 'open':
                setOpen(true);
                document.body.classList.add('overflow-h');
                break;
            case 'close':
                setOpen(false);
                document.body.classList.remove('overflow-h');
                break;
            default:
                break;
        }

        if (callback) {
            callback();
        }
    };

    return (
        <>
            <Box sx={css.header.base(matches)}>
                <Container maxWidth="lg" sx={css.header.container}>
                    <Box sx={css.header.wraper}>
                        <Box onClick={() => scrollToElement(homeRef)}>
                            <Image
                                src={logo}
                                alt='Mariana Ramos Advocacia'
                                objectFit='contain'
                                maxHeight={43}
                            />
                        </Box>
                        {matches && (
                            <Stack direction='row' alignItems='stretch' spacing={0.5} height='100%'>
                                {menu.map(({ id, title, hash, ref }, i) => {
                                    if (!ref) return

                                    const active = hash === location.hash

                                    return (
                                        <Typography
                                            key={id}
                                            variant="body1"
                                            component='a'
                                            href={hash}
                                            fontWeight={typography.fontWeightMedium}
                                            sx={{
                                                ...css.header.nav.item(active).wraper,
                                                color: active ? mainColor : palette.lightdark
                                            }}
                                            onClick={() => {(window as any).dataLayer.push({'event': id}); scrollToElement(ref)}}>
                                            {title}
                                            <Box sx={{...css.header.nav.item(active).overlay, background: mainColor }} />
                                        </Typography>
                                    )
                                })}
                                <Box sx={css.header.contactButton.wrapper}>
                                    <Button
                                        className="MuiButton outlined primary"
                                        variant="outlined"
                                        size="medium"
                                        sx={{
                                            ...css.header.contactButton.button,
                                            borderColor: `${mainColor} !important`,
                                            color: `${mainColor} !important`
                                        }}
                                        onClick={() => scrollToElement(contactRef)}>
                                        Contato
                                    </Button>
                                </Box>
                            </Stack>
                        )}
                        {!matches && (
                            !open ? (
                                <IconButton
                                    color="secondary"
                                    aria-label="open menu"
                                    onClick={() => handleMenu({ type: 'open' })}>
                                    <MenuIcon sx={css.header.nav.mobile.menu.openIcon} />
                                </IconButton>
                            ) : (

                                <IconButton
                                    color="secondary"
                                    aria-label="close menu"
                                    onClick={() => handleMenu({ type: 'close' })}>
                                    <CloseIcon sx={css.header.nav.mobile.menu.closeIcon} />
                                </IconButton>
                            )
                        )}
                    </Box>
                </Container>
            </Box>
            {!matches && open && (
                <MobileMenu titleColor={mainColor}>
                    <Stack alignItems='stretch'>
                        {menu.map(({ id, title, hash, ref }) => {
                            if (!ref) return

                            return (
                                <Typography
                                    key={id}
                                    variant="body1"
                                    component='a'
                                    href={hash}
                                    onClick={() => {(window as any).dataLayer.push({'event': id}); handleMenu({ type: 'close', callback: () => scrollToElement(ref) })}}
                                    fontWeight={typography.fontWeightMedium}
                                    sx={css.header.nav.mobile.item(hash === location.hash).wraper}>
                                    {title}
                                </Typography>
                            )
                        })}
                    </Stack>
                </MobileMenu>
            )}
        </>
    )
}

export { Header };