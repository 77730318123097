import { useMediaQuery, useTheme } from "@mui/material"

const sx = () => {
    const { palette } = useTheme()
    const matches = useMediaQuery('(min-width:600px)');

    const css = {
        social: {
            button: {
                backgroundColor: palette.lightdark,
                width: matches ? '54px' : '44px',
                maxWidth: matches ? '54px' : '44px',
                height: matches ? '54px' : '44px',
                '&:hover': {
                    backgroundColor: palette.lightdark,
                },
                '& > a': {
                    display: 'flex',
                },
                '& > img': {
                    width: matches ? '21px' : '16px',
                },
                '& > img.facebook': {
                    width: matches ? '16px' : '12px',
                },
            },
        }
    }

    return { css }
}

export { sx }