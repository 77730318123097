
const scrollToElement = (r: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
        const lastChildElement = r.current;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        lastChildElement && (lastChildElement.style.animation = 'scrollIntoView')
        lastChildElement && (lastChildElement.style.animationDelay = '50000ms')
    }, 500);
};

export { scrollToElement }