import FacebookIcon from '../assets/img/svg-icons/facebook.svg';
import XTwitterIcon from '../assets/img/svg-icons/x-twitter.svg';
import InstagramIcon from '../assets/img/png-icons/instagram.png';
import WhatsappIcon from '../assets/img/png-icons/whatsapp.png';

export const socialList = [
    {
        id: 'FacebookClick',
        title: 'facebook',
        href: '#!',
        icon: FacebookIcon,
        active: false,
    },
    {
        id: 'TwitterClick',
        title: 'x twitter',
        href: '#!',
        icon: XTwitterIcon,
        active: false,
    },
    {
        id: 'AW-16559094342/bcwwCPaIg7EZEMb0_tc9',
        title: 'instagram',
        href: 'https://www.instagram.com/marianaramos.adv/',
        icon: InstagramIcon,
        active: true,
    },
    {
        id: 'AW-16559094342/5ZeICPOIg7EZEMb0_tc9',
        title: 'whatsapp',
        href: 'https://wa.me/5511977480600?text=Ol%C3%A1%2C%20vim%20do%20seu%20website%20e%20gostaria%20de%20um%20atendimento',
        icon: WhatsappIcon,
        active: true,
    },
];