interface PensionLaw {
    id: string
    icon: string
    title: string
    text: string
}

export const pensionLawServices: PensionLaw[] = [
    {
        id: '1',
        icon: '',
        title: 'Ação Aposentadoria por Tempo de Contribuição',
        text: 'Buscamos o direito à aposentadoria para quem completou o tempo necessário de contribuições ao INSS.'
    },
    {
        id: '2',
        icon: '',
        title: 'Ação Aposentadoria Especial',
        text: 'Ajudamos a obter a aposentadoria especial para trabalhadores expostos a condições nocivas, com tempo reduzido de contribuição.'
    },
    {
        id: '3',
        icon: '',
        title: 'Ação Aposentadoria por Idade',
        text: 'Assistimos na obtenção da aposentadoria por idade para quem atingiu a idade mínima exigida pelo INSS.'
    },
    {
        id: '4',
        icon: '',
        title: 'Aposentadoria por Invalidez',
        text: 'Auxiliamos na concessão da aposentadoria para trabalhadores incapacitados permanentemente para o trabalho.'
    },
    {
        id: '5',
        icon: '',
        title: 'Auxílio-Acidente',
        text: 'Buscamos o benefício para trabalhadores que sofrem acidentes que resultem em sequelas e redução da capacidade laboral.'
    },
    {
        id: '6',
        icon: '',
        title: 'Auxílio-Doença',
        text: 'Ajudamos na obtenção do benefício temporário para trabalhadores incapacitados por doença ou acidente.'
    },
    {
        id: '7',
        icon: '',
        title: 'Auxílio-Reclusão',
        text: 'Ajudamos na concessão do benefício para dependentes de segurados presos.'
    },
    {
        id: '8',
        icon: '',
        title: 'Pensão por Morte',
        text: 'Auxiliamos os dependentes de segurados falecidos a obterem a pensão por morte.'
    },
    {
        id: '9',
        icon: '',
        title: 'Salário-Maternidade',
        text: 'Garantimos o benefício para seguradas que tiveram filho ou adotaram uma criança.'
    },
    {
        id: '10',
        icon: '',
        title: 'Benefício Assistencial ao Idoso e ao Deficiente (LOAS)',
        text: 'Assistimos na obtenção do benefício para idosos e pessoas com deficiência em situação de vulnerabilidade.'
    },
    {
        id: '11',
        icon: '',
        title: 'Concessão de Aposentadoria Rural',
        text: 'Ajudamos trabalhadores rurais a obterem a aposentadoria, considerando o tempo de trabalho no campo.'
    }
]