import React, { Ref } from 'react'

import { Box, Container, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Headline } from '../headline';
import { socialList } from '../../../static';
import { sx } from './sx';

interface ContactProps {
    refContact: Ref<unknown>
    titleColor: string
}

const Contact: React.FC<ContactProps> = ({refContact, titleColor}) => {

    const { palette } = useTheme()
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();

    return (
        <Box
            ref={refContact}
            mt={matches ? '5rem' : '30px'}
            pt={matches ? '5rem' : '30px'}
            pb={matches ? '5rem' : '30px'}
            sx={{
                backgroundColor: palette.grey[50],
            }}>
            <Container maxWidth="lg">

                <Headline
                    title="Fale Comigo"
                    titleColor={titleColor}
                    subtitle="<p>Fale comigo pelo telefone: (11) 97748-0600</p> <p>ou através das minhas redes sociais</p>"
                />
                <Box mt={matches ? '1.5625rem' : '.9375rem'}>

                    <Stack direction='row' justifyContent='center' spacing={1}>
                        {socialList.map(({ id, title, href, icon, active }) => {
                            if (!active) return;
                            return (
                                <IconButton
                                    key={id}
                                    color="secondary"
                                    aria-label={title}
                                    sx={css.social.button}
                                >
                                    <Box
                                        component='a'
                                        rel='noopener noreferrer'
                                        href={href}
                                        onClick={() => { (window as any).gtag('event', 'conversion', {'send_to': id}) }}
                                        target='_blank'
                                        sx={{
                                            '&>div': {
                                                lineHeight: 0,
                                            }
                                        }}>
                                        <img src={icon} alt="social link"/>
                                    </Box>
                                </IconButton>
                            )
                        })}
                    </Stack>

                </Box>
            </Container>
        </Box>
    )
}

export { Contact }