import { Home } from "../pages/home";
import { LaborLawLandingPage } from "../pages/laborLaw";
import { PensionLawLandingPage } from "../pages/pensionLaw";

export const stackRoutes = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/trabalhista',
        element: <LaborLawLandingPage />
    },
    {
        path: '/previdenciario',
        element: <PensionLawLandingPage />
    }
];