import Box from "@mui/material/Box"
import { Container, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { sx } from './sx';
import React from "react";
import { socialList } from '../../../../static';

interface MobileMenuProps {
    children: React.ReactNode;
    titleColor: string
}

const MobileMenu: React.FC<MobileMenuProps> = ({ children, titleColor }: MobileMenuProps) => {
    const { css } = sx();
    const { typography, palette } = useTheme();

    return (
        <>
            <Box sx={{
                position: 'fixed',
                background: 'white',
                top: '61px',
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 140,
                textAlign: 'center'
            }}>
                <Box mt={'.625rem'}>
                    {children}
                </Box>
                <Divider light />
                <Container maxWidth="md">
                    <Box mt='1.25rem'>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize='1.125rem'
                            fontWeight={typography.fontWeightBold}
                            sx={{
                                color: titleColor,
                            }}>
                            Fale comigo
                        </Typography>
                        <Typography
                            variant="body1"
                            component='p'
                            fontSize='.8125rem'
                            fontWeight={typography.fontWeightLight}
                            sx={{
                                color: palette.lightdark,
                            }}>
                            <p>Fale comigo pelo telefone: (11) 97748-0600</p>
                            <p>ou através das minhas redes sociais</p>
                        </Typography>
                    </Box>
                    <Box mt='.9375rem'>
                        <Stack direction='row' justifyContent={'center'} spacing={1}>
                            {socialList.map(({ id, title, href, icon, active }) => {
                                if (!active) return;
                                return (
                                    <IconButton
                                        key={id}
                                        color="secondary"
                                        aria-label={title}
                                        sx={css.header.nav.mobile.social.button}
                                    >
                                        <Box
                                            component='a'
                                            rel='noopener noreferrer'
                                            href={href}
                                            onClick={() => { (window as any).gtag('event', 'conversion', {'send_to': id}) }}
                                            target='_blank'
                                            sx={{
                                                '&>div': {
                                                    lineHeight: 0,
                                                }
                                            }}>
                                            <img src={icon} alt="social link" />
                                        </Box>
                                    </IconButton>
                                )
                            })}
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export { MobileMenu };