// icons
import house from '../assets/img/png-icons/icon-1.png';
import briefcase from '../assets/img/png-icons/icon-2.png';
import gavel from '../assets/img/png-icons/icon-3.png';
import personCane from '../assets/img/png-icons/icon-4.png';
import userDoctor from '../assets/img/png-icons/icon-5.png';
import handcuffs from '../assets/img/png-icons/icon-6.png';
import houseUser from '../assets/img/png-icons/icon-7.png';

// covers
import familia from '../assets/img/familia.png';
import trabalhista from '../assets/img/trabalhista.png';
import civil from '../assets/img/civil.png';
import previdencia from '../assets/img/previdencia.png';
import saude from '../assets/img/saude.png';
import criminal from '../assets/img/criminal.png';
import imoveis from '../assets/img/imoveis.png';

export const data = [
    {
        id: 'FamiliaSucessoesClick',
        title: 'Familia e sucessões',
        preview: `Atuação no ramo jurídico dedicado a questões familiares, abrangendo direitos e obrigações. Especializações incluem Pensão Alimentícia, Guarda, Divórcio, Testamento, Adoção, e outros.`,
        text: `É o ramo jurídico que se dedica às questões familiares e aos direitos e obrigações decorrentes dessas relações é conhecida como Direito de Família. E nossas principais áreas de atuação envolvem temas como Pensão Alimentícia, Guarda, Pacto Antenupcial, Divórcio, Testamento, Investigação de Paternidade, Adoção, Reconhecimento e Dissolução de União Estável, Regulamentação de Visita de Menores, Planejamento Familiar e Sucessório, Partilha de Bens, Inventário, Interdição, Tutela, Exoneração de Alimentos e Pedido de Revisão.`,
        icon: house,
        cover: familia,
    },
    {
        id: 'TrabalhistaClick',
        title: 'Trabalhista',
        preview: `Defesa de empregados e empresas, buscando reparação para direitos suprimidos, abrangendo demissões, periculosidade, rescisões, horas extras e vínculo empregatício.<br/><br/>`,
        text: `Atuação em prol dos empregados e das empresas na defesa de seus direitos, buscando uma reparação justa para os direitos que foram suprimidos, sonegados ou não cumpridos adequadamente ao longo do contrato de trabalho. Nossas principais áreas de atuação são: demissão por justa causa, adicional de periculosidade e insalubridade, rescisão indireta, horas extras e reconhecimento de vínculo empregatício. Nossa equipe está empenhada em oferecer suporte jurídico especializado e eficiente, garantindo a proteção dos interesses de nossos clientes e a defesa de seus direitos trabalhistas.`,
        icon: briefcase,
        cover: trabalhista,
    },
    {
        id: 'CivilEConsumidorClick',
        title: 'Civil e Consumidor',
        preview: `Áreas incluem Danos Materiais e Morais, Contratos, Cobranças, Negócios Jurídicos, e defesa de consumidores em casos diversos, como produtos defeituosos e serviços inadequados.`,
        text: `Nossas principais áreas de atuação incluem Danos Materiais e Morais, Responsabilidade Civil, Contratos, Cobranças, Negócios Jurídicos, contratos, dívidas, questões condominiais, locações, direitos e obrigações, Relações de Consumo e Obrigações. Além disso, estamos preparados para defender os interesses dos consumidores em casos envolvendo produtos com defeitos, serviços prestados de forma inadequada, vícios redibitórios, problemas contratuais e práticas abusivas de comércio.`,
        icon: gavel,
        cover: civil,
    },
    {
        id: 'PrevidenciarioClick',
        title: 'Previdenciário',
        preview: `Assessoria para benefícios e aposentadorias perante o INSS, abrangendo Aposentadoria por Idade, Auxílio Acidente, Pensão por Morte, e Revisão de Aposentadoria e Benefícios.`,
        text: `Oferecemos serviços especializados em assessoria jurídica para defesas administrativas e judiciais na área previdenciária perante o INSS. Nossa equipe é dedicada a auxiliar pessoas físicas em questões relacionadas a benefícios e aposentadorias previdenciárias, abrangendo diversas causas importantes, tais como: Aposentadoria por Idade, Auxílio Acidente, Doença, Reclusão, Aposentadoria por Tempo de Contribuição, Pensão por Morte, Aposentadoria por Invalidez, Salário-Maternidade, Benefício assistencial ao idoso e à pessoa com deficiência (BPC/LOAS), Aposentadoria Especial por Tempo de Contribuição e Revisão de Aposentadoria e Benefícios.`,
        icon: personCane,
        cover: previdencia,
    },
    {
        id: 'SaudeClick',
        title: 'Saúde',
        preview: `Proteção dos direitos dos consumidores contra abusos de operadoras e defesa em questões relacionadas ao SUS, abrangendo negativas, cirurgias não autorizadas e cobranças indevidas.`,
        text: `Nossa atuação visa proteger os direitos dos consumidores frente aos abusos praticados pelas operadoras de planos de saúde, bem como garantir uma defesa sólida dos pacientes em questões relacionadas ao Sistema Único de Saúde (SUS). E nossas principais áreas de atuação envolvem temas como: Aumento abusivo, Negativa de prótese, Recusa de internação, Problemas no atendimento, Cirurgias não autorizadas, Cobranças indevidas, Fornecimento de medicamentos, Home care e Cancelamento de planos.`,
        icon: userDoctor,
        cover: saude,
    },
    {
        id: 'CriminalClick',
        title: 'Criminal',
        preview: `Atuação na defesa e representação de vítimas em processos criminais, incluindo Pedido de Revogação de Prisão, Audiência de Custódia, e Crimes Contra a Pessoa e a Vida.<br/><br/>`,
        text: `Atuamos na defesa dos acusados e na representação das vítimas, oferecendo nossos serviços a pessoas físicas e departamentos jurídicos de empresas em processos criminais e investigatórios, abrangendo tanto a esfera administrativa quanto o inquérito policial. Nossas principais áreas de atuação são: Pedido de Revogação de Prisão, Acompanhamento de Prisão em Flagrante, Realização de Audiência de Custódia, Averiguação de Intimação, Despacho de Liminares em Habeas Corpus , Memoriais, Prisão preventiva/temporária, Crimes Contra da Pessoa e a Vida (Júri Popular) , Revisão Criminal e Crimes no Trânsito.`,
        icon: handcuffs,
        cover: criminal,
    },
    {
        id: 'RegularizacaoImoveisClick',
        title: 'Regularização de Imóveis',
        preview: `Aprimoramento judicial e extrajudicial de imóveis, envolvendo Usucapião, Inventário, Adjudicação, Divisão Amigável, Reintegração de Posse, e Extinção de Condomínio.`,
        text: `Aprimoramos o seu imóvel, garantindo sua regularização tanto judicialmente quanto extrajudicialmente. Utilizamos procedimentos como Usucapião, Inventário, Adjudicação, Divisão Amigável, desdobro e Desmembramento de Imóveis, além de cuidar de questões contratuais, Averbação da Obra, Reintegração de Posse, Manutenção de Posse, Imissão na Posse e Extinção de Condomínio. Conte com nossos serviços especializados para assegurar a conformidade e a tranquilidade em relação ao seu patrimônio imobiliário.`,
        icon: houseUser,
        cover: imoveis,
    },
];