import { Box, Grid, useMediaQuery } from "@mui/material"
import { FC, Ref } from "react"
import { Headline } from "../headline";

interface ReviewProps {
    refReview: Ref<unknown>
    titleColor: string
}

const Reviews: FC<ReviewProps> = ({ refReview, titleColor }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box pt={matches ? '5rem' : '30px'} ref={refReview}>
            <Headline
                title="Avaliações"
                titleColor={titleColor}
                subtitle="Veja oque as pessoas estão falando de mim"
            />
            <Box mt='20px'>
                <Grid container spacing={2}>
                    <div style={{marginLeft: '17px'}} className="elfsight-app-ec89dd07-a047-435e-8048-77f7791484ea" data-elfsight-app-lazy></div>
                </Grid>
            </Box>
        </Box>
    )
}

export { Reviews }