import { Box, Grid, useMediaQuery } from "@mui/material";
import { FC, Ref } from "react";
import { Headline } from "../headline";

interface LocationProps {
    refLocation: Ref<unknown>
    titleColor: string
}

const Location: FC<LocationProps> = ({ refLocation, titleColor }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box pt={matches ? '5rem' : '30px'} ref={refLocation}>
            <Headline
                title="Localização"
                titleColor={titleColor}
                subtitle="Veja onde está localizado meu escritório"
            />
            <Box mt='20px'>
                <Grid container spacing={2}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.633896665672!2d-46.64419448824857!3d-23.545666560929003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59f9285056b9%3A0x40760ae0e7a589a6!2sMARIANA%20RAMOS%20VIEIRA%20ADVOGADA!5e0!3m2!1spt-BR!2sbr!4v1706133989527!5m2!1spt-BR!2sbr"
                        width="100%" height="450" style={{border: "0", marginLeft: '17px'}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Grid>
            </Box>
        </Box>
    )
}

export { Location }