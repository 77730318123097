import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material"
import { sx } from './sx';

interface MainBannerProps {
    onClick?: () => void;
    mainImage: string;
    theme: {
        backgroundColor: string
        textColor: string
        buttonColor: string
        buttonTextColor: string
        image: {
            size: {
                desktop: string
                mobile: string
            },
            marginLeft: {
                desktop: string
                mobile: string
            }
        }
    }
    preTitle: string
    title: string
    description: string
}

const MainBanner: React.FC<MainBannerProps> = ({ onClick, mainImage, theme, preTitle, title, description }) => {
    const { typography } = useTheme();
    const matches600 = useMediaQuery('(min-width:600px)');

    const { css } = sx();

    return (
        <Box sx={{...css.hero.base, backgroundColor: theme.backgroundColor}}>

            <Container maxWidth="md" sx={css.hero.container}>
                <Box sx={css.hero.content.base}>
                    <Box>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize={matches600 ? '.875rem' : '.625rem'}
                            letterSpacing={4}
                            textTransform='uppercase'
                            gutterBottom
                            fontWeight={typography.fontWeightRegular}
                            sx={{
                                color: theme.textColor,
                            }}>
                            {preTitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize={matches600 ? '48px' : '22px'}
                            lineHeight={1.2}
                            fontWeight={typography.fontWeightBold}
                            whiteSpace='nowrap'
                            sx={{
                                color: theme.textColor,
                            }}
                            dangerouslySetInnerHTML={{__html: title}}>
                        </Typography>
                        <Typography
                            variant="body1"
                            component='h1'
                            fontSize={matches600 ? '.875rem' : '.75rem'}
                            fontWeight={matches600 ?
                                typography.fontWeightRegular :
                                typography.fontWeightLight}
                            mb={matches600 ? '1.5rem' : '.875rem'}
                            sx={{
                                color: theme.textColor,
                            }}>
                            {description}
                        </Typography>
                        <Box>
                            <Button
                                className="MuiButton contained primary"
                                sx={{
                                    backgroundColor: `${theme.buttonColor} !important`,
                                    color: theme.buttonTextColor
                                }}
                                variant="contained"
                                size={matches600 ? "large" : 'medium'}
                                onClick={onClick}>
                                Fale comigo
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        ...css.hero.content.image,
                        ml: !matches600 ? theme.image.marginLeft.mobile : theme.image.marginLeft.desktop,
                        '& > img': { width: !matches600 ? theme.image.size.mobile : theme.image.size.desktop } 
                    }}>
                        <img
                            src={mainImage}
                            alt='Mariana Ramos Advocacia'
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export { MainBanner };
