interface LaborLaw {
    id: string
    icon: string
    title: string
    text: string
}

export const laborLawServices: LaborLaw[] = [
    {
        "id": "1",
        "icon": "briefcase",
        "title": "Reconhecimento de vínculo empregatício",
        "text": "Processo legal que busca a formalização do vínculo de emprego, garantindo ao trabalhador os direitos trabalhistas como carteira assinada, FGTS, férias e 13º salário."
    },
    {
        "id": "2",
        "icon": "money",
        "title": "Pagamento de verbas rescisórias, salários, 13º salário e férias",
        "text": "Procedimento de quitação das obrigações financeiras da empresa para com o trabalhador no término do contrato de trabalho, incluindo salários atrasados, 13º salário proporcional e férias."
    },
    {
        "id": "3",
        "icon": "bank",
        "title": "FGTS e multa rescisória de 40%",
        "text": "Depósitos mensais no Fundo de Garantia do Tempo de Serviço (FGTS) e pagamento da multa de 40% sobre o saldo do FGTS em casos de demissão sem justa causa."
    },
    {
        "id": "4",
        "icon": "balance-scale",
        "title": "Equivalência e equiparação salarial",
        "text": "Ações para garantir salários iguais para funções idênticas, visando eliminar discriminações e assegurar a isonomia salarial."
    },
    {
        "id": "5",
        "icon": "gavel",
        "title": "Indenizações por mudanças prejudiciais no contrato de trabalho",
        "text": "Compensações financeiras devidas ao trabalhador por modificações no contrato de trabalho que resultem em prejuízos ou perda de direitos."
    },
    {
        "id": "6",
        "icon": "shuffle",
        "title": "Desvio de função",
        "text": "Situação onde o trabalhador executa tarefas diferentes das previstas em seu contrato de trabalho, podendo ter direito a uma compensação ou ajuste salarial."
    },
    {
        "id": "7",
        "icon": "layers",
        "title": "Acúmulo de funções",
        "text": "Condição em que o empregado exerce múltiplas funções sem a devida remuneração adicional, podendo buscar um ajuste salarial correspondente."
    },
    {
        "id": "8",
        "icon": "user-shield",
        "title": "Reintegração ao emprego",
        "text": "Direito de retorno ao trabalho em casos específicos como gestantes, membros da CIPA, e trabalhadores afastados por acidente ou doença ocupacional."
    },
    {
        "id": "9",
        "icon": "heartbeat",
        "title": "Indenizações por doenças ocupacionais e acidentes de trabalho",
        "text": "Compensações financeiras por doenças ou lesões decorrentes das atividades laborais, assegurando suporte ao trabalhador afetado."
    },
    {
        "id": "10",
        "icon": "exclamation-circle",
        "title": "Danos morais por assédio e tratamento indigno",
        "text": "Indenizações devidas em casos de assédio moral ou sexual e outras formas de tratamento desrespeitoso ou degradante no ambiente de trabalho."
    },
    {
        "id": "11",
        "icon": "shield-alt",
        "title": "Defesa em processos trabalhistas",
        "text": "Atuação na elaboração de defesas para empresas ou empregados em ações judiciais trabalhistas, buscando proteger os direitos envolvidos."
    },
    {
        "id": "12",
        "icon": "handshake",
        "title": "Negociação de Acordos Coletivos de Trabalho (ACT)",
        "text": "Mediação e elaboração de acordos entre sindicatos e empresas para definir condições de trabalho, benefícios e outras obrigações mútuas."
    },
    {
        "id": "13",
        "icon": "clipboard-list",
        "title": "Defesa em investigações do Ministério Público do Trabalho (MPT)",
        "text": "Representação legal de empresas ou trabalhadores em inquéritos promovidos pelo MPT, buscando a resolução de questões trabalhistas de maneira justa."
    },
    {
        "id": "14",
        "icon": "file-alt",
        "title": "Contestação de autuações do Ministério do Trabalho (MTE)",
        "text": "Elaboração de defesas contra multas e sanções impostas pelo MTE, visando a revisão ou anulação das penalidades aplicadas."
    },
    {
        "id": "15",
        "icon": "file-contract",
        "title": "Elaboração e revisão de cláusulas contratuais de emprego",
        "text": "Criação e ajustes nas cláusulas de contratos de trabalho para garantir que estejam em conformidade com a legislação e os interesses das partes envolvidas."
    },
    {
        "id": "16",
        "icon": "file-signature",
        "title": "Criação de contratos acessórios",
        "text": "Desenvolvimento de contratos adicionais, como de aluguel para empregados, bolsas de estudo e outras vantagens, assegurando clareza e conformidade legal."
    }
]